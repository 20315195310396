import { hashEmail } from '@/lib/utils/cryptoUtils'
import { Basket, Product, StripePaymentMethod } from '../checkout/types'
import {
  AccordionClickEvent,
  CarouselChangeEvent,
  CheckoutEvent,
  CheckoutPageEvent,
  ChoosePlanViewEvent,
  CtaEvent,
  EmailListType,
  GtmEvent,
  LandingViewEvent,
  LearnPageViewEvent,
  LinkPosition,
  LoginEvent,
  OptionalCtaParams,
  Section,
  SignUpEvent,
  VideoEvent,
  WaitlistSignUpEvent,
  WhatsIncludedViewEvent,
} from './typesV2'

export const sendGtmEvent = (event: GtmEvent) => {
  // @ts-ignore
  window?.zoeDataLayer?.push(event)
}

const getQuizUserId = () => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('cUserId='))
    ?.split('=')[1]
}

const PLATFORM_NUTRITION_SITE = 'nutrition_site'

export const trackCtaClick = (section: Section, textOrImageUrl: string, params?: OptionalCtaParams) => {
  const { email, ...optionalCtaParams } = params || {}
  const event: CtaEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'user_click',
    click_type: 'cta',
    section: section,
    link_text_image: textOrImageUrl,
    user_id: getQuizUserId(),
    ...optionalCtaParams,
    link_position: params?.link_position ?? 'page_body',
    ...(email && { hashed_email: hashEmail(email) }),
  }
  sendGtmEvent(event)
}

export const trackVideoEvent = (
  section: Section,
  action: 'Play' | 'Pause' | 'Complete',
  videoTitle: string,
  videoWatched?: number,
) => {
  const gtmEvent: VideoEvent = {
    event: 'video_action',
    section: section,
    platform: PLATFORM_NUTRITION_SITE,
    video_action: action,
    video_name: videoTitle,
    video_length: videoWatched,
    user_id: getQuizUserId(),
  }
  sendGtmEvent(gtmEvent)
}

export const trackLandingViewEvent = (section: Section, pageName: string, pageVersion: string) => {
  const event: LandingViewEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'landing_view',
    section: section,
    user_id: getQuizUserId(),
    page_name: pageName,
    page_version: pageVersion,
  }
  sendGtmEvent(event)
}

export const trackWhatsIncludedViewEvent = () => {
  const event: WhatsIncludedViewEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'view_item_list',
    section: 'plans',
    user_id: getQuizUserId(),
    page_name: 'whats_included',
  }
  sendGtmEvent(event)
}

export const trackChoosePlanViewEvent = (pageName: string, productSelected?: string) => {
  const event: ChoosePlanViewEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'view_item',
    section: 'plans',
    user_id: getQuizUserId(),
    page_name: pageName,
    product_selected: productSelected,
  }
  sendGtmEvent(event)
}

export type TrackSignUpEventProps = {
  section: Section
  email?: string | null
  emailList: EmailListType
  linkPosition?: LinkPosition
  textOrImageUrl?: string
}

export const trackSignupEvent = ({
  section,
  emailList,
  email,
  linkPosition = 'page_body',
  textOrImageUrl,
}: TrackSignUpEventProps) => {
  const event: SignUpEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'sign_up',
    section: section,
    user_id: getQuizUserId(),
    hashed_email: hashEmail(email),
    email_list: emailList,
    link_position: linkPosition,
    link_text_image: textOrImageUrl,
  }
  sendGtmEvent(event)
}

export type TrackWaitListSignUpEventProps = TrackSignUpEventProps & {
  waitlistSelectedCountry: string
  waitlistMarketingConsent: boolean
  waitlistSelectedHearAbout?: string
  waitlistSelectedHearOther?: string
  waitlistSelectedMotivation?: string
}

export const trackWaitlistSignUp = ({
  section,
  email,
  emailList,
  linkPosition = 'page_body',
  waitlistSelectedCountry,
  waitlistSelectedHearAbout,
  waitlistMarketingConsent = true,
  waitlistSelectedHearOther,
  waitlistSelectedMotivation,
}: TrackWaitListSignUpEventProps) => {
  const event: WaitlistSignUpEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'sign_up',
    section: section,
    user_id: getQuizUserId(),
    hashed_email: hashEmail(email),
    email_list: emailList,
    link_position: linkPosition,
    waitlist_selected_country: waitlistSelectedCountry,
    waitlist_selected_hear_about: waitlistSelectedHearAbout,
    waitlist_marketing_consent: waitlistMarketingConsent,
    waitlist_selected_hear_other: waitlistSelectedHearOther,
    waitlist_selected_motivation: waitlistSelectedMotivation,
  }
  sendGtmEvent(event)
}

export type trackAccordionClickEventProps = {
  section: Section
  textOrImageUrl: string
  linkPosition?: string
}

export type trackCardClickEventProps = {
  section: Section
  textOrImageUrl: string
  linkPosition?: string
}

export interface trackCarouselClickEventProps extends trackCardClickEventProps {}

export const trackAccordionClickEvent = ({
  section,
  textOrImageUrl,
  linkPosition = 'page_body',
}: trackAccordionClickEventProps) => {
  const event: AccordionClickEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'user_click',
    click_type: 'accordion',
    section: section,
    link_text_image: textOrImageUrl,
    user_id: getQuizUserId(),
    link_position: linkPosition,
  }
  sendGtmEvent(event)
}

export const trackCarouselEvent = (textOrImageUrl: string, section: Section | null, linkPosition = 'page_body') => {
  const event: CarouselChangeEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'user_click',
    click_type: 'carousel',
    section: section || 'cms',
    link_text_image: textOrImageUrl,
    user_id: getQuizUserId(),
    link_position: linkPosition,
  }
  sendGtmEvent(event)
}

export const trackCardClickEvent = ({
  section,
  textOrImageUrl,
  linkPosition = 'page_body',
}: trackCardClickEventProps) => {
  const event: AccordionClickEvent = {
    platform: PLATFORM_NUTRITION_SITE,
    event: 'user_click',
    click_type: 'card',
    section: section,
    link_text_image: textOrImageUrl,
    user_id: getQuizUserId(),
    link_position: linkPosition,
  }
  sendGtmEvent(event)
}

export type TrackLearnArticlePageViewProps = {
  articleCategory: string
  articleTitle: string
  articleVersion: string
  articleAuthor: string
}

export const trackLearnArticlePageView = ({
  articleCategory,
  articleTitle,
  articleVersion,
  articleAuthor,
}: TrackLearnArticlePageViewProps) => {
  const event: LearnPageViewEvent = {
    event: 'learn_content_viewed',
    platform: PLATFORM_NUTRITION_SITE,
    section: 'learn',
    user_id: getQuizUserId(),
    learn_category: articleCategory,
    page_name: articleTitle,
    page_version: articleVersion,
    page_author: articleAuthor,
  }
  sendGtmEvent(event)
}

export const getCheckoutEvent = (
  pageName: string,
  event: CheckoutPageEvent,
  selectedProducts: Product[],
  email?: string | null,
): CheckoutEvent => ({
  page_name: pageName,
  event: event,
  platform: PLATFORM_NUTRITION_SITE,
  hashed_email: hashEmail(email),
  section: 'checkout',
  user_id: getQuizUserId(),
  product_selected: selectedProducts.map((product) => product.zoeProductId).join(', '),
  ecommerce: {
    currency: selectedProducts && selectedProducts[0].currency,
    value:
      selectedProducts.filter((product) => product.payNow).reduce((total, product) => total + product.price, 0) / 100, //TODO: making it the total paynow amount for now. But let's check with Analytics if that's what they are looking for
    items: selectedProducts.map((selectedProduct) => ({
      item_id: selectedProduct.stripePriceId,
      item_name: selectedProduct.displayName,
      item_brand: 'ZOE',
      item_category: selectedProduct.productCategory,
      price: selectedProduct.price / 100,
      discount: selectedProduct.priceAfterDiscount
        ? (selectedProduct.price - selectedProduct.priceAfterDiscount) / 100
        : 0,
      quantity: selectedProduct.quantity || 1,
    })),
  },
})

type LoginEventProps = {
  section: Section
  productType: string
}
export const trackLoginEvent = (props: LoginEventProps) => {
  const event: LoginEvent = {
    event: 'login',
    platform: PLATFORM_NUTRITION_SITE,
    section: props.section,
    user_type: 'member',
    user_id: getQuizUserId(),
    page_name: 'login',
    product_type: props.productType,
  }
  sendGtmEvent(event)
}

export const getDaily30CheckoutEvent = ({
  pageName,
  event,
  selectedProduct,
  userType,
  discount,
  email,
}: TrackDaily30CheckoutEventProps): CheckoutEvent => ({
  page_name: pageName,
  event: event,
  platform: PLATFORM_NUTRITION_SITE,
  section: 'checkout',
  user_id: getQuizUserId(),
  hashed_email: hashEmail(email),
  user_type: userType,
  product_selected: selectedProduct.zoeProductId,
  product_type: 'daily30',
  ecommerce: selectedProduct && {
    currency: selectedProduct.currency,
    value: (selectedProduct.price - (discount ?? 0)) / 100,
    items: [
      {
        item_id: selectedProduct.zoeProductId,
        item_name: selectedProduct.nickname,
        item_brand: 'ZOE',
        item_category: 'daily30',
        price: selectedProduct.price / 100,
        discount: discount ? discount / 100 : 0,
        quantity: selectedProduct.quantity || 1,
      },
    ],
  },
})

export type TrackDaily30CheckoutEventProps = {
  pageName: string
  email?: string | null
  event: CheckoutPageEvent
  selectedProduct: Product
  userType: 'guest' | 'member'
  discount?: number
}

export const trackDaily30CheckoutEvent = (props: TrackDaily30CheckoutEventProps) => {
  const checkoutEvent: CheckoutEvent = getDaily30CheckoutEvent(props)
  sendGtmEvent(checkoutEvent)
}

const getCheckoutPurchaseEvent = (
  basket: Basket,
  orderNumber: string,
  email: string | null | undefined,
  paymentMethod: StripePaymentMethod,
): CheckoutEvent => {
  const selectedProducts = basket.items
  const couponCode = basket.discount?.submitted_promotion_code || ''

  return {
    page_name: 'purchase',
    event: 'purchase',
    platform: PLATFORM_NUTRITION_SITE,
    section: 'checkout',
    user_id: getQuizUserId(),
    hashed_email: hashEmail(email),
    product_selected: selectedProducts.map((product) => product.zoeProductId).join(', '),
    ecommerce: {
      currency: selectedProducts && selectedProducts[0].currency,
      value:
        selectedProducts.filter((product) => product.payNow).reduce((total, product) => total + product.price, 0) / 100, //TODO: making it the total paynow amount for now. But let's check with Analytics if that's what they are looking for
      tax: basket.tax_amount ? parseFloat((basket.tax_amount / 100).toFixed(2)) : 0,
      shipping: 0, // Hardcoded for now as we have not charged shipping fees
      coupon: couponCode,
      transaction_id: orderNumber,
      items: selectedProducts.map((selectedProduct) => ({
        item_id: selectedProduct.stripePriceId,
        item_name: selectedProduct.displayName,
        item_brand: 'ZOE',
        item_category: selectedProduct.productCategory,
        price: selectedProduct.price / 100,
        coupon: couponCode,
        discount: selectedProduct.priceAfterDiscount
          ? (selectedProduct.price - selectedProduct.priceAfterDiscount) / 100
          : 0,
        quantity: selectedProduct.quantity || 1,
      })),
      payment_type: paymentMethod,
    },
  }
}

export type TrackCheckoutEventProps = {
  pageName: string
  event: CheckoutPageEvent
  selectedProducts: Product[]
  email?: string | null
}

export const trackCheckoutEvent = ({ pageName, event, selectedProducts, email }: TrackCheckoutEventProps) => {
  const checkoutEvent: CheckoutEvent = getCheckoutEvent(pageName, event, selectedProducts, email)
  sendGtmEvent(checkoutEvent)
}

export type TrackCheckoutPurchaseEventProps = {
  basket: Basket
  orderNumber: string
  email?: string | null
  userType?: 'guest' | 'member'
  paymentMethod: StripePaymentMethod
}

export const trackCheckoutPurchaseEvent = ({
  basket,
  orderNumber,
  email,
  paymentMethod,
}: TrackCheckoutPurchaseEventProps) => {
  const event: CheckoutEvent = getCheckoutPurchaseEvent(basket, orderNumber, email, paymentMethod)
  sendGtmEvent(event)
}

const getDaily30CheckoutPurchaseEvent = (
  basket: Basket,
  orderNumber: string,
  userType: string | undefined,
  email: string | null | undefined,
  paymentMethod: StripePaymentMethod,
): CheckoutEvent => {
  const selectedProduct = basket.items?.[0]
  const couponCode = basket.discount?.submitted_promotion_code || ''

  return {
    page_name: 'purchase',
    event: 'purchase',
    platform: PLATFORM_NUTRITION_SITE,
    section: 'checkout',
    user_id: getQuizUserId(),
    hashed_email: hashEmail(email),
    product_selected: selectedProduct?.zoeProductId,
    product_type: 'daily30',
    user_type: userType ? 'member' : 'guest',
    ecommerce: {
      currency: selectedProduct?.currency,
      value: (selectedProduct.priceAfterDiscount ?? selectedProduct.price) / 100,
      tax: basket.tax_amount ? parseFloat((basket.tax_amount / 100).toFixed(2)) : 0,
      shipping: 0,
      coupon: couponCode,
      transaction_id: orderNumber,
      items: [
        {
          item_id: selectedProduct.zoeProductId,
          item_name: selectedProduct.nickname,
          item_brand: 'ZOE',
          item_category: 'daily30',
          price: (selectedProduct.priceAfterDiscount ?? selectedProduct.price) / 100,
          discount: selectedProduct.priceAfterDiscount
            ? (selectedProduct.price - selectedProduct.priceAfterDiscount) / 100
            : 0,
          quantity: selectedProduct.quantity || 1,
        },
      ],
      payment_type: paymentMethod,
    },
  }
}

export const trackDaily30CheckoutPurchaseEvent = ({
  basket,
  orderNumber,
  userType,
  email,
  paymentMethod,
}: TrackCheckoutPurchaseEventProps) => {
  const event: CheckoutEvent = getDaily30CheckoutPurchaseEvent(basket, orderNumber, userType, email, paymentMethod)
  sendGtmEvent(event)
}

export type TrackD30PDPViewEventProps = {
  productName?: string
  productPrice?: number
  itemId?: string
  pageName?: string
}

export const trackD30PDPViewEvent = ({ productName, productPrice, itemId, pageName }: TrackD30PDPViewEventProps) => {
  const item = itemId &&
    productName &&
    productPrice && {
      item_id: itemId,
      item_name: productName,
      item_brand: 'ZOE',
      item_category: 'daily30',
      price: productPrice / 100,
      discount: 0,
      quantity: 1,
    }

  const event: CheckoutEvent = {
    event: 'view_item',
    platform: PLATFORM_NUTRITION_SITE,
    section: 'd30',
    user_id: getQuizUserId(),
    page_name: pageName || 'pdp',
    product_type: 'daily30',
    product_selected: itemId || '',
    ecommerce: {
      currency: 'GBP',
      value: 0,
      items: item ? [item] : undefined,
    },
  }
  sendGtmEvent(event)
}
