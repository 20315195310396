export type ThemeType = typeof theme

export const theme = {
  colours: {
    white: '#ffffff',
    brand: '#fa7268',
    brandDarkPink: '#F9DEDC',
    brandDarkBlue: '#445DC3',
    brandHover: '#d9635a',
    primary: '#24262b',
    secondary: '#565a5c',
    tertiary: '#888b8c',
    quarternary: '#AAACAD',
    disabled: '#FFB6B0',
    predictBlue: '#082a5d',
    backgroundBrand: '#fff3f2',
    backgroundBlue: '#f1f4f8',
    backgroundPrimary: '#ffffff',
    backgroundSecondary: '#f7f7f7',
    backgroundTertiary: '#eeeeef',
    backgroundQuaternary: '#e2e2e2',
    feedbackExcellent: '#98AB02',
    greenHighlight: '#73a797',
    tableBorder: '#c4c4c4',
    separatorBorder: '#e1e3ea',
    podcast: {
      bgColor: '#FAC828',
      color: '#000',
    },
    text: {
      h1: '#2d3246',
      h2: '#2d3246',
    },
  },
  breakpoints: {
    smallMobile: '375px',
    mobile: '600px',
    tablet: '960px',
    desktop: '1280px',
    largeDesktop: '1560px',
    containerNarrow: '684px',
    containerMedium: '800px',
    containerWide: '860px',
  },

  v2: {
    decorativeColours: {
      pear400: '#D1DCBC',
      teal100: '#F1F7F6',
    },
    feedback: {
      excellent: '#98AB02',
    },
    text: {
      primary: '#24262B',
      secondary: '#565A5C',
      green: '#98AB02',
      yellow: '#FFD100',
      tertiary: '#888B8C',
      white: '#FFFFFF',
      dark: '#4E5254',
    },
    secondary: {
      action: '#2D3246',
      pressed: '#12141C',
      disabled: '#697297',
      onDisabled: '#CBCEDD',
    },
    surface: {
      cream100: '#FAF8F5',
      cream300: '#FFFCF6',
      cream500: '#FBF8F3',
      cream600: '#E8DECF',
      dark: '#2D3246',
      extraDark: '#222530',
      white: '#FFFFFF',
      yellow: '#FAC828',
      lightGreen: '#EDF1E5',
      greyBlue: '#EEF3F0',
      purple: {
        subtle: '#F0F1F5',
      },
    },
    onSurface: {
      border: '#B7B5B1',
      subtext: '#697297',
      subdued: {
        border: '#E2DFDB',
      },
      subduedText: '#4B526E',
      text: '#2D3246',
    },
    highlight: {
      bright: '#8C94B5',
    },
    interactive: {
      bright: '#445DC3',
      subdued: '#8999D9',
      surface: '#EEEEEF',
    },
    critical: {
      bright: '#FF3D3D',
      light: '#fa7268',
    },
    warning: {
      bright: '#FF8A00',
      surface: '#FFF3E5',
    },
    custom: {
      homepageSecondaryYellow: '#FDBC3E',
      homepageTertiaryYellow: '#FFB82E',
      yellow200: '#FFE366',
    },
    button: {
      primary: {
        bgColor: '#FFD100',
        hoverColor: '#EFB706',
        textColor: '#2D3246',
        disabledColor: '#8999D9',
      },
      secondary: {
        bgColor: '#FFFFFF',
        hoverColor: '#FFFFFF',
        borderColor: '#445DC3',
        borderHoverColor: '#445DC3',
        textColor: '#445DC3',
        disabledColor: '#8999D9',
      },
      bright: {
        bgColor: '#445DC3',
        hoverColor: '#354BA4',
        disabledColor: '#8999D9',
        textColor: '#FFFEFB',
      },
      brightBorder: {
        bgColor: '#ffffff',
        borderColor: '#445DC3',
        hoverColor: '#354BA4',
        hoverBorderColor: '#354BA4',
        disabledColor: '#8999D9',
        textColor: '#445DC3',
      },
      dark: {
        bgColor: '#2D3246',
        hoverColor: '#12141C',
        textColor: '#FFFEFB',
        disabledColor: '#697297',
      },
      white: {
        bgColor: '#FFFFFF',
        hoverColor: '#FFFFFF',
        textColor: '#4B526E',
        disabledColor: '#FFFFFF',
      },
    },
    input: {
      radio: {
        bgColor: '#F7F7F7',
      },
    },
    font: {
      lotaGrotesqueLight: 'LotaGrotesqueAlt1-Light, sans-serif',
      lotaGrotesqueRegular: 'LotaGrotesqueAlt1-Regular, sans-serif',
      lotaGrotesqueSemiBold: 'LotaGrotesqueAlt1-SemiBold, sans-serif',
      nantes: 'Nantes, sans-serif',
      nantesItalic: 'Nantes-Italic, Nantes, sans-serif',
    },
    typography: {
      display: {
        xxxl: {
          fontSize: '80px',
          lineHeight: '100px',
        },
        xxl: {
          fontSize: '72px',
          lineHeight: '84px',
        },
        xl: {
          fontSize: '56px',
          lineHeight: '74px',
        },
        l: {
          fontSize: '48px',
          lineHeight: '60px',
        },
        m: {
          fontSize: '44px',
          lineHeight: '58px',
        },
        s: {
          fontSize: '40px',
          lineHeight: '54px',
        },
        xs: {
          fontSize: '36px',
          lineHeight: '48px',
        },
        xxs: {
          fontSize: '32px',
          lineHeight: '48px',
        },
      },
      body: {
        xxl: {
          fontSize: '32px',
          lineHeight: '42px',
        },
        xl: {
          fontSize: '28px',
          lineHeight: '40px',
        },
        l: {
          fontSize: '24px',
          lineHeight: '32px',
        },
        ml: {
          fontSize: '22px',
          lineHeight: '28px',
        },
        m: {
          fontSize: '20px',
          lineHeight: '26px',
        },
        s: {
          fontSize: '18px',
          lineHeight: '24px',
        },
        xs: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        xxs: {
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
      label: {
        l: {
          fontSize: '14px',
          lineHeight: '20px',
        },
        m: {
          fontSize: '12px',
          lineHeight: '16px',
        },
        s: {
          fontSize: '10px',
          lineHeight: '14px',
        },
      },
    },
  },
}
