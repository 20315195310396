import { getBannerContent } from '@/graphql/Banner'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface BannerContent {
  text: string
  enabled: boolean
}

interface BannerContextProps {
  bannerContent: BannerContent | null
}

const BannerContext = createContext<BannerContextProps | undefined>(undefined)

interface BannerProviderProps {
  children: React.ReactNode
}

export const BannerProvider: React.FC<BannerProviderProps> = ({ children }) => {
  const [bannerContent, setBannerContent] = useState<BannerContent | null>(null)

  useEffect(() => {
    const fetchBannerContent = async () => {
      // const content = await getBannerContent()
      setBannerContent({
        enabled: false,
        text: '',
      })
    }

    fetchBannerContent()
  }, [])

  return <BannerContext.Provider value={{ bannerContent }}>{children}</BannerContext.Provider>
}

export const useBanner = () => {
  const context = useContext(BannerContext)
  if (context === undefined) {
    throw new Error('useBanner must be used within a BannerProvider')
  }
  return context
}
